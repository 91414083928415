import { Text, Title, Anchor } from '@mantine/core';
import { animate, motion, useInView } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import stackDetail from '../images/stack-detail.jpg';
import papermaking from '../images/papermaking.jpg';
import paperExcellence from '../images/paper-excellence-location.svg';
import weaving from '../images/weaving.jpg';
import water from '../images/water-treatment.jpg';
import workers1 from '../images/workers-1.jpg';
import workers1_2x from '../images/workers-1-2x.jpg';
import workers2 from '../images/workers-2.jpg';
import workers2_2x from '../images/workers-2-2x.jpg';
import workers3 from '../images/workers-3.jpg';
import workers3_2x from '../images/workers-3-2x.jpg';
import workers4 from '../images/workers-4.jpg';
import workers4_2x from '../images/workers-4-2x.jpg';
import workers5 from '../images/workers-5.jpg';
import workers5_2x from '../images/workers-5-2x.jpg';
import bubbles from '../videos/bubbles.mp4';
import doc from '../assets/橙氧中英文产品手册V3-精简版.pdf';
import './cases.less';
import SEO from "../components/seo";

const images = [
  { thumbnail: workers1, raw: workers1_2x, className: 'img1', },
  { thumbnail: workers2, raw: workers2_2x, className: 'img2', },
  { thumbnail: workers3, raw: workers3_2x, className: 'img3', },
  { thumbnail: workers4, raw: workers4_2x, className: 'img4', },
  { thumbnail: workers5, raw: workers5_2x, className: 'img5', },
];

function Counter({ from, to }: { from: number, to: number}) {
  const nodeRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        if (node) {
          node.textContent = value.toFixed(0);
        }
      }
    });

    return () => controls.stop();
  }, [from, to]);

  return <span ref={nodeRef} />;
}

function Cases() {
  const numberRef = useRef<HTMLSpanElement>(null);
  const hasBeenInView = useInView(numberRef, { once: true });
  const [previewImage, setPreviewImage] = useState<string>();

  return (<div className='cases'>
    <section className="bg">
      <video src={bubbles} autoPlay loop muted></video>
      <div className="text-tag">双氧水是非常理想的绿色氧化剂，有着广泛的用途</div>
    </section>
    <section className="application">
      <Title className='title' order={1}>双氧水是非常理想的绿色氧化剂，有着广泛的用途</Title>
      <Title className='sub-title' order={2}>橙氧科技的双氧水现场生产设备将颠覆性改变下列各领域</Title>
      <div className='industry papermaking'>
        <img src={papermaking} />
        <div className='industry-mask'>
          <div className='text-container'>
            <Title className='name' order={3}>纸浆与造纸漂白</Title>
            <Text className='description'>在纸浆漂白中，该技术可直接用于双氧水强化碱抽提段（EOP或EP段）、双氧水漂白段（P段）或者漂白工段结束时用于防止纸浆返黄。在机械浆厂中，它可用于大多数双氧水预处理和漂白工段。除大幅降低成本外，双氧水现场生产设备还可提高生产安全性和供应可靠性。整套方案不需要昂贵的改造或大量空间，非常适合永久或临时性的双氧水使用。</Text>
          </div>
        </div>
      </div>
      <div className='industry weaving'>
        <img src={weaving} />
        <div className='industry-mask'>
          <div className='text-container'>
            <Title className='name' order={3}>纺织品漂白</Title>
            <Text className='description'>天然棉和其他纤维材料中含有的杂质会导致材料出现灰白色和黄色。双氧水作为漂白剂可以去除杂质颜色，以便纺织品的染色。与破坏性较大的氯气漂白剂不同，双氧水能够在去除着色剂的同时不损坏纤维。橙氧科技的技术可用于降低助剂成本并提高助剂供给的稳定性。</Text>
          </div>
        </div>
      </div>
      <div className='industry water'>
        <img src={water} />
        <div className='industry-mask'>
          <div className='text-container'>
            <Title className='name' order={3}>水处理行业</Title>
            <Text className='description'>双氧水可应用于饮用水和污水处理的不同工艺中。它可以用作氧化剂，去除饮用水厂中的异味和臭气。也可以与紫外线或臭氧结合来促进高级氧化过程。有时双氧水也可以代替或减少氯或氯基氧化剂的使用，以避免消毒副产物的产生。现场生产双氧水还可以降低高昂的双氧水运输成本，并且降低高浓度氧化物储存的危险。</Text>
          </div>
        </div>
      </div>
    </section>
    <section className="emission-reduction">
      <div className='title-container'>
        <Title className='title' order={1}>橙氧科技电化学产双氧水比化工法每吨减少二氧化碳排放2.492吨</Title>
        <Title className='sub-title' order={2}>我们的目标是为全球双氧水行业每年减排超25,000,000吨</Title>
      </div>
      <motion.span
        className='number'
        ref={numberRef}
      >
        {hasBeenInView && <Counter from={0} to={25000000} />}
      </motion.span>
      <img src={stackDetail}/>
    </section>
    <section className="papermaking-customer">
      <div className="title-container">
        <Title className='title' order={1}>我们携手全球制浆造纸巨头</Title>
        <Title className='sub-title' order={2}>仅消耗空气中的氧气、水和电，用绿色技术颠覆双氧水的生产与使用</Title>
      </div>
      <img className='papermakingMap' src={paperExcellence} />
      <div className='gallary'>
        {images.map(({ className, thumbnail, raw}, index) => (
          <img key={index} className={className} src={thumbnail} onClick={() => setPreviewImage(raw)} />
        ))}
      </div>
      {previewImage && <div className="preview-mask" onClick={() => setPreviewImage(undefined)}>
        <img src={previewImage} />
      </div>}
    </section>
    {/* <section className="download">
      <div className="container">
        <Title id="download" order={1}>获得双氧水现场生产设备的电子资料</Title>
        <Anchor
          href={doc}
          target="_blank"
        >点击下载</Anchor>
      </div>
    </section> */}
  </div>);
}

export default Cases;

export const Head = () => (<SEO />)
